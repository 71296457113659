<template>
    <b-card title="E-Learning List">
      <b-row class="justify-content-between mb-2">
        <b-col cols="12" lg="4" class="d-flex align-items-center">
          <router-link
            to="/create-e-learning"
            class="btn waves-effect waves-float waves-light btn-primary"
          >
            Create E-Learning
          </router-link>
        </b-col>
      </b-row>
      <b-row class="justify-content-start">
          <b-col cols="12" lg="4">
            <div class="form-group">
              <label>Type: </label>
              <v-select
                v-model="filter.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="--Type--"
                :options="types"
                :reduce="(type) => type.value"
              />
            </div>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="form-group">
              <label>Class Type: </label>
              <v-select
                v-model="filter.class_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="--Class Type--"
                :options="class_types"
                :reduce="(type) => type.value"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="form-group mb-0">
              <label for="search">Search:</label>
              <input
                id="search"
                type="text"
                class="form-control"
                placeholder="Search: Title"
                v-model="filter.search"
              />
            </div>
          </b-col>
      </b-row>
  
      <Table
        :result="result"
        :eLearning="eLearning"
        :is-loading="isLoading"
        :get-data="getData"
        :delete-item="deleteItem"
        :current-page="currentPage"
      />
    </b-card>
  </template>
  
  <script>
  import vSelect from "vue-select";
  import Table from "@/components/e-learning/TableList.vue";
  import { successNotification, errorNotification } from "@/auth/utils";
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BSpinner,
    VBModal,
  } from "bootstrap-vue";
  import Ripple from "vue-ripple-directive";
  import _ from "lodash";
  export default {
    components: {
      vSelect,
      Table,
      BCard,
      BCol,
      BRow,
      BButton,
      BSpinner,
      BFormInput,
    },
    directives: {
      "b-modal": VBModal,
      Ripple,
    },
    watch: {
      filter: {
        handler: _.debounce(function() {
          this.getData();
        }, 300),
        deep: true,
      },
    },
    data() {
      return {
        currentPage: 1,
        isLoading: false,
        result: [],
        eLearning: [],
        link: "",
        isLoadingExport: false,
        formPayload: {
          name: "",
        },
        editId: null,
        filter: {
        },
        types: [
          {
            name: "General",
            value: "general",
          },
          {
            name: "Competition",
            value: "competition",
          },
          {
            name: "Financial",
            value: "financial",
          },
          {
            name: "Female Future Leader",
            value: "female-future-leader",
          },
        ],
        class_types: [
        {
            name: "Series",
            value: "series",
          },
          {
            name: "Single",
            value: "single",
          },
        ],
        validations: "",
      };
    },
    setup() {
      return {
        successNotification,
        errorNotification,
      };
    },
    created() {
      this.getData();
    },
    methods: {
      getData(page) {
        this.isLoading = true;
        this.currentPage = page;
        const queryParams = this.filter;
        queryParams.page = page;
        this.$http
          .get("/api/v1/admin/e-learning", {
            params: queryParams,
          })
          .then((response) => {
            this.result = response.data.data.meta;
            this.eLearning = response.data.data.items;
            this.currentPage = response.data.data.meta.currentPage;
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      deleteItem(slug) {
        this.$swal({
          title: "Are you sure?",
          text: "Are you sure to delete this E-Learning?",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.$http
              .delete(`/api/v1/admin/e-learning/${slug}`)
              .then((response) => {
                this.getData(this.currentPage);
                this.$swal({
                  icon: "success",
                  title: "Success!",
                  text: "E-Learning successfully deleted",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(
                    this,
                    "Oops!",
                    error.response.data.meta.messages
                  );
                }
              });
          }
        });
      },
    },
  };
  </script>
  
  <style lang="scss"></style>
  